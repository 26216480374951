/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Circle,
  Group,
  Image,
  Layer,
  Line,
  Rect,
  Stage,
  Text,
} from 'react-konva';
import { useHistory, useLocation } from 'react-router-dom';
import useImage from 'use-image';
import { PageHead } from '../lib/components/page-head';
import { DEFAULT_HUSTLES } from './questions-data';
import DownloadImage from './save-to-file';

const QuestionImage = ({
  src,
  x,
  y,
  width,
  height,
  label,
  total,
  id,
  isCustom,
}) => {
  const [image] = useImage(src, 'anonymous');
  const xPosition = useMemo(() => {
    if (isCustom) {
      if (total <= 4) {
        const offset = Math.min(38, 8 + (label.length - 2) * 30);
        return label.length > 1 ? x - offset : x;
      }
      // Reduce by 8 for length 2, by 18 for length 3, and so on
      const offset = Math.min(38, 8 + (label.length - 2) * 20);
      return label.length > 1 ? x - offset : x;
    } else {
      return label.length > 4 ? x - 5 : x;
    }
  }, [label.length, x, isCustom]);
  const fontSize = useMemo(() => {
    if (total <= 4) {
      // four images
      return 40 - 0.15 * (label.length - 50);
    } else {
      return 40 - 0.15 * (label.length + 20);
    }
  }, [total, label.length]);
  return image ? (
    <>
      <Rect
        x={x} // Slight offset for border thickness
        y={y}
        width={width + 10} // Adjusted width to account for border
        height={height + 10} // Adjusted height to account for border
        stroke="white"
        strokeWidth={1} // Adjust thickness as needed
      />
      <Image image={image} x={x} y={y} width={width} height={height} />
      <Text
        text={label}
        x={xPosition}
        y={total <= 4 ? y + 32 : y + 18} // Adjust this to place the text further down inside the image
        width={width}
        fontSize={fontSize}
        align="center"
        fill="white" // White text to contrast with image
        fontStyle="500" // Optional: bold style for better visibility
        shadowColor="black" // Optional: black shadow to improve contrast
        shadowBlur={5} // Optional: shadow blur for better readability
        fontFamily={`'Gotham A', 'Gotham B', 'futura-pt', 'futura', 'Arial', sans-serif`}
      />
    </>
  ) : (
    <></>
  );
};

export default function HustleCanvas({ cleanProps }) {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const item = localStorage.getItem('hustlegraphic')
    // Check if the current path is '/hustlegraphic/view' and navigate to another page on refresh
    if (!item) {
      history.push('/hustlegraphic'); // Replace '/another-page' with the desired route
    } else {
      localStorage.removeItem('hustlegraphic')
    }
    
    
  }, [location, history]);
  const officeNameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const emailRef = useRef(null);

  const [isDownload, setIsDownload] = useState(false);
  const [totalImages, setTotalImages] = useState();

  const stageRef = useRef(null);
  const canvasWidth = 540;
  const headerHeight = 60;
  const footerHeight = 50;
  const gridPadding = 0;
  const [textWidths, setTextWidths] = useState({
    officeName: 0,
    phoneNumber: 0,
    email: 0,
  });

  const [images, setImages] = useState(
    cleanProps?.questions?.map((question) => ({
      image: question.image,
      isCustom: question.isCustom,
    })) || []
  );

  if (images.length % 2 !== 0) {
  }
  useEffect(() => {
    let updatedImages = [...images];
    setTotalImages(updatedImages.length);
    const randomNum = Math.floor(Math.random() * 3);
    const defaultImage = DEFAULT_HUSTLES[randomNum];
    if (updatedImages.length === 1) {
      // Add 1 default image if there's only 1 image
      updatedImages.push({ image: defaultImage, isCustom: false });
    } else if (updatedImages.length === 3) {
      // Add 1 default image if there are 3 images
      updatedImages.push({ image: defaultImage, isCustom: false });
    } else if (updatedImages.length > 4 && updatedImages.length % 3 !== 0) {
      // For more than 4 images, add default image(s) to make the total number divisible by 3
      const imagesToAdd = 3 - (updatedImages.length % 3);
      for (let i = 0; i < imagesToAdd; i++) {
        const defaultImage = DEFAULT_HUSTLES[i % DEFAULT_HUSTLES.length]; // Use i to cycle through the images

        updatedImages.push({ image: defaultImage, isCustom: false });
      }
    }

    setImages(updatedImages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleanProps?.questions]);
  let gridColumns = 2;
  if (images.length > 4) {
    gridColumns = 3; // Show 3 images per row after the 4th image
  }
  const gridRows = Math.ceil(images.length / gridColumns);
  const canvasHeight =
    headerHeight + footerHeight + gridRows * (canvasWidth / gridColumns);

  const cellWidth = (canvasWidth - gridPadding * 2) / gridColumns;
  const cellHeight = cellWidth; // Keep the cells square for the images

  const downloadCanvasAsImage = () => {
    if (!stageRef.current) return;

    // Set to download mode
    setIsDownload(true);

    setTimeout(() => {
      const uri = stageRef.current.toDataURL({ pixelRatio: 4 }); // Adjust pixelRatio for better quality
      const link = document.createElement('a');
      link.download = 'hustlegraphic.png';
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Reset download mode after download
      setIsDownload(false);
    }, 1000);
  };

  useEffect(() => {
    // Use type assertion to avoid TypeScript error
    document.fonts.ready.then(() => {
      setTextWidths({
        officeName: officeNameRef.current?.getTextWidth() || 0,
        phoneNumber: phoneNumberRef.current?.getTextWidth() || 0,
        email: emailRef.current?.getTextWidth() || 0,
      });
    });
  }, [cleanProps.info.email, cleanProps.info.phoneNumber, cleanProps.info.officeName, officeNameRef, phoneNumberRef, emailRef]);
  const totalTextWidth = useMemo(
    () =>
      textWidths.officeName +
      (cleanProps?.info?.phoneNumber ? textWidths.phoneNumber + 15 : 0) + // Add space for the dot
      (cleanProps?.info?.email ? textWidths.email + 15 : 0), // Add space for the dot
    [
      cleanProps?.info?.email,
      cleanProps?.info?.phoneNumber,
      textWidths.email,
      textWidths.officeName,
      textWidths.phoneNumber,
    ]
  );

  const startX = useMemo(
    () => (canvasWidth - totalTextWidth) / 2,
    [totalTextWidth]
  ); // Center the text group in the footer

  const textRef = useRef(null);
  const textWidth = useMemo(
    () => (textRef.current ? textRef.current.getTextWidth() : 0),
    []
  );
  const adjustedStartX = useMemo(
    () => canvasWidth / 2 + textWidth / 2 + 5,
    [textWidth]
  ); // Add a small offset if needed to ensure the line is right next to the text
 
  return (
    <div style={{ position: 'relative' }}>
      {isDownload && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <Stage width={canvasWidth} height={canvasHeight} ref={stageRef}>
        <Layer>
          {/* Rectangle (main canvas) */}
          <Rect
            x={0}
            y={0}
            width={canvasWidth}
            height={canvasHeight}
            fill="lightgray"
          />

          {/* Header */}
          {/* Header Background */}
          <Rect
            x={0}
            y={0}
            width={canvasWidth}
            height={headerHeight}
            fill="#454d54"
          />

          <Text
            x={-10} // Start at the left edge of the canvas
            y={-10} // Adjust Y as needed for vertical centering
            width={canvasWidth} // Ensure the width spans the full canvas width
            height={headerHeight}
            text={cleanProps?.info.agentName.toUpperCase() ?? ''}
            fontSize={18}
            align="center" // Center-align the text
            fontStyle="bold"
            verticalAlign="middle"
            fill="white"
            fontFamily={`'Gotham A', 'Gotham B', 'futura-pt', 'futura', 'Arial', sans-serif`}
          />
          <Line
            points={[50, 35 + 7, 40 + 100, 35 + 7]} // Adjust points: [startX, startY, endX, endY]
            stroke="white"
            strokeWidth={1}
          />
          <Text
            ref={textRef}
            x={0}
            y={12}
            width={canvasWidth - 20}
            height={headerHeight}
            text="12 MONTHS OF #REMAXHUSTLE"
            fontSize={14}
            align="center"
            verticalAlign="middle"
            fill="white"
          />
          {isDownload ? (
            <Line
              points={[
                adjustedStartX+105, // Dynamically set the start X position based on text width
                35 + 7, // StartY
                canvasWidth - 40, // EndX
                35 + 7, // EndY
              ]}
              stroke="white"
              strokeWidth={1}
            />
          ) : (
            <Line
              points={[
                canvasWidth - (20 + 100), // StartX (canvas width minus padding and line length)
                35 + 7, // StartY
                canvasWidth - 40, // EndX (canvas width minus padding)
                35 + 7, // EndY
              ]}
              stroke="white"
              strokeWidth={1}
            />
          )}
          {/* */}

          {/* Grid of Images */}
          {/* loadedImages */}
          {images.map(
            (el, index) => {
              const isDefaultImage = DEFAULT_HUSTLES.includes(el.image);

              const image = el.image;
              const answer = isDefaultImage
                ? ''
                : cleanProps?.questions[index].answer.number;
              const row = Math.floor(index / gridColumns);
              const col = index % gridColumns;
              const x = col * cellWidth + gridPadding;
              const y = row * cellHeight + headerHeight + gridPadding;

              return (
                <QuestionImage
                  isCustom={el.isCustom}
                  id={index}
                  total={totalImages}
                  key={index}
                  src={image}
                  x={x}
                  y={y}
                  width={cellWidth - gridPadding}
                  height={cellHeight - gridPadding}
                  label={answer}
                />
              );
            }
          )}

          {/* Footer Background */}
          <Rect
            x={0}
            y={canvasHeight - footerHeight}
            width={canvasWidth}
            height={footerHeight}
            fill="#454d54"
          />
          <Line
            points={[
              0, // StartX
              canvasHeight - footerHeight, // StartY (top of the footer)
              canvasWidth, // EndX
              canvasHeight - footerHeight, // EndY (same Y position)
            ]}
            stroke="white"
            strokeWidth={1} // Adjust thickness as needed
          />
          {/* Footer */}
          <Rect
            x={0}
            y={canvasHeight - footerHeight}
            width={canvasWidth}
            height={footerHeight}
            fill="#454d54"
          />

          {/* Group the content for better alignment */}
          {/* Footer Content */}
          <Group>
            {/* Centered Office Name */}
            <Text
              ref={officeNameRef}
              x={startX} // Start at the calculated position
              y={canvasHeight - footerHeight + 20}
              text={cleanProps?.info?.officeName}
              fontSize={16}
              align="left"
              verticalAlign="middle"
              fill="white"
              fontStyle="500"
              fontFamily="futura-pt, sans-serif"
              letterSpacing={1.5}
            />

            {/* Dot between Office Name and Phone Number */}

            {cleanProps?.info?.phoneNumber && isDownload && (
              <Circle
              x={
                startX +
                textWidths.officeName + // End of the officeName text
                10 // Add a fixed margin to space the circle from the text
              }// Adjusted to be after the office name
                y={canvasHeight - footerHeight + 28}
                radius={2}
                fill="white"
              />
            )}
            {cleanProps?.info?.phoneNumber && !isDownload && (
              <Circle
                x={startX + textWidths.officeName + 10} // Adjusted to be after the office name
                y={canvasHeight - footerHeight + 28}
                radius={2}
                fill="white"
              />
            )}
            {/* Centered Phone Number */}
            {cleanProps?.info?.phoneNumber && (
              <Text
                ref={phoneNumberRef}
                x={startX + textWidths.officeName + 17} // Start after the dot
                y={canvasHeight - footerHeight + 21}
                text={cleanProps?.info?.phoneNumber}
                fontSize={16}
                align="left"
                verticalAlign="middle"
                fill="white"
                fontStyle="500"
                fontFamily="futura-pt, sans-serif"
                letterSpacing={1.5}
              />
            )}

            {/* Dot and Email if present */}
            {cleanProps?.info?.email && (
              <>
                {/* Dot between Phone Number and Email */}

                {cleanProps?.info?.phoneNumber && isDownload && (
                  <Circle
                    x={
                      startX +
                      textWidths.officeName +
                      20 +
                      (textWidths.phoneNumber ? textWidths.phoneNumber + 15 : 0)
                    } // Adjust based on phone number and dot spacing
                    y={canvasHeight - footerHeight + 28}
                    radius={2}
                    fill="white"
                  />
                )}
                {cleanProps?.info?.phoneNumber && !isDownload && (
                  <Circle
                    x={
                      startX +
                      textWidths.officeName +
                      20 +
                      (textWidths.phoneNumber ? textWidths.phoneNumber + 15 : 0)
                    } // Adjust based on phone number and dot spacing
                    y={canvasHeight - footerHeight + 28}
                    radius={2}
                    fill="white"
                  />
                )}

                {/* Email */}
                <Text
                  ref={emailRef}
                  x={
                    startX +
                    textWidths.officeName +
                    15 +
                    (textWidths.phoneNumber ? textWidths.phoneNumber + 30 : 0)
                  } // Start after the dot
                  y={canvasHeight - footerHeight + 20}
                  text={cleanProps?.info?.email}
                  fontSize={16}
                  align="left"
                  verticalAlign="middle"
                  fill="white"
                  fontStyle="500"
                  fontFamily="futura-pt, sans-serif"
                  letterSpacing={1.5}
                />
              </>
            )}
          </Group>
        </Layer>
      </Stage>
      <div className="form-wrap">
        <PageHead>SHARE</PageHead>
        <div className="share-buttons">
          <DownloadImage onClick={downloadCanvasAsImage} />
        </div>
      </div>
    </div>
  );
}
